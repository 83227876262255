import React, {useState, useEffect} from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade';
import Animation from "../components/animation/animation"

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 59% 39%;
  padding: 0 5vw;
  grid-gap: 2%;
  margin-bottom: 60px;
  h2 {
    color: ${({ theme }) => theme.primaryColor};
  }
  img {
    width: 100%;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    img {
      grid-row: 1;
    }
  }
`;

export default function Servizio({ pageContext }) {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 960) setIsMobile(true)
  }, []);
  return (
    <Layout pageTitle={`${pageContext.title} - Eugenio Maucieri`} pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
      <Fade left duration={1000}>
        <h1>{pageContext.title}</h1>
      </Fade>
      <ContentWrapper>
        <Animation isMobile={isMobile} position="left">
          <div dangerouslySetInnerHTML={{ __html: pageContext.html }}></div>
        </Animation>
        <Animation isMobile={isMobile} position="top">
          <img src={pageContext.image} alt={`servizio_${pageContext.title}`} />
        </Animation>
      </ContentWrapper>
    </Layout>
  )
}
